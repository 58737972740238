//
// Demo utilities
//
.each-fade {
    display: flex;
    width: 100%;
  }
  
  .each-fade > div {
    width: 75%;
  }
  
  .each-fade > div img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .each-fade p {
    width: 25%;
    font-size: 1em;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin: 0;
    background: #adceed;
  }
@include media-breakpoint-only(xs) { 
    .responsive-block {
        width: 100%;
      }
 }
 @include media-breakpoint-only(sm) { 
    .responsive-block {
        width: 100%;
      }
 }
 @include media-breakpoint-only(md) { 
    .responsive-block {
        width: 100%;
      }
 }
.demo-square {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 100px;

  // Sizing
  &.demo-square-sm {
    height: 75px;
    width: 75px;
  }

  &.demo-square-lg {
    height: 150px;
    width: 150px;
  }

  &.demo-square-xl {
    height: 175px;
    width: 175px;
  }
}
.Receivables-section{
  border-left: solid;
    }
