@page {
    size: 4in 6in portrait;
  }
  
  @media print {
    .pagebreak {
      page-break-before: always;
    }
  }
  @media print {
    html,
    body {
      height: initial !important;
      overflow: hidden !important;
      -webkit-print-color-adjust: exact;
      font-size: 14px;
      width: 4in;
      height: 6in;
      float: left;
      text-align: center;
    }
  }
  
  @media print {
    .ticket {
      float: left;
      clear: both;
      text-align: center;
    }
    .breakpage {
      float: left;
      clear: left;
      display: block;
      page-break-before: always;
      text-align: center;
    }
    @page {
      float: left;
      size: 4in 6in landscape;
      padding-left: 1rem;
      text-align: center;
    }
  }
  