.bdRMro .smooth-dnd-container {
  min-height: 500px;
}
.bdRMro .smooth-dnd-container:hover {
  background: rgb(63, 94, 251);
  background: linear-gradient(
    180deg,
    rgba(63, 94, 251, 0) 0%,
    rgba(54, 153, 255, 0.5) 50%,
    rgba(252, 70, 107, 0) 100%
  );
}

.PalletItems tbody tr:hover {
  background: #3699ff;
  color: #fff;
  cursor: pointer;
}

.PalletItems td {
  border: 1px solid white !important;
}
.PalletItems th {
  border: 1px solid #f3f6f9 !important;
}

.PalletItems tbody th {
  border: 1px solid white !important;
}
tr.danger {
  background-color: #ffe2e5;
}
tr.warning {
  background-color: #fff4de;
}
tr.success {
  background-color: #c9f7f5;
}
tr.primary {
  background-color: #e1f0ff;
}
