.ScrollingMenu-item {
  padding: 6px 20px;
  margin: 5px 10px;
  user-select: none;
  cursor: pointer;
  border: none;
}
.ScrollingMenu-item.active {
  background: #8950fc;
  border-radius: 5px;
  color: #fff;
}
.scroll-menu-arrow {
  padding: 0 !important;
}
.fSize {
  font-size: 25px;
  color: #8950fc;
}
.fSize2 {
  font-size: 25px;
  color: #8950fc;
}
.scroll-menu-arrow {
  padding: 20px;
  cursor: pointer;
}
.ScrollingMenuApp {
  height: 50px !important;
  border: 1px solid #f3f6f9 !important;
}
