#pg-photo-container img{
    width: 85% !important;
    height: auto !important;
}
#pg-photo-container{
    height: auto !important;
}

.pdfViewer .container div{
    height: auto !important;
}

.pg-viewer-wrapper{
    overflow-y: hidden;
}
.react-grid-Grid{
    min-height: 450px !important; 
}